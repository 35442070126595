<template>
  <div class="d-flex justify-content-center align-items-center vh-100">
    <!-- <div class="d-flex flex-column align-items-center">
      <b-spinner class="mb-2"> </b-spinner>
      <div>
        Chuyển hướng thanh toán...
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  mounted() {},
  methods: {}
};
</script>
o
